import { useEffect } from 'react';

import { SignedOut, RedirectToSignIn, SignedIn, useAuth } from '@clerk/nextjs';
import { useRouter } from 'next/router';

import { useTypedClient } from '@/api/jsonApiClient';
import { Spinner } from '@/components/Spinner';
import { getSessionCookie } from '@/utils/session';

const Index = () => {
  const client = useTypedClient();
  const router = useRouter();
  const { sessionId } = useAuth();

  useEffect(() => {
    (async () => {
      if (sessionId) {
        const response = await client.fetchMany(
          [
            'private-deals',
            {
              page: {
                size: 1,
              },
            },
          ],
          {
            headers: {
              clerk_jwt: getSessionCookie() || '',
              clerk_session: sessionId || '',
            },
          }
        );

        if (response.data === null || response.data === undefined) {
          router.replace('/500');
        } else if (response.data.length === 0) {
          router.replace('/dashboard');
        } else {
          router.replace('/dashboard/reporting');
        }
      }
    })();
  }, [sessionId]);

  return (
    <div className="flex flex-col h-screen my-auto items-center justify-center bgimg bg-cover bg-indigo-600">
      <SignedIn>
        <div>
          <img
            className="object-cover h-[35px]"
            src="//adaptmx.com/wp-content/uploads/2020/07/AdaptMX-logo-Oriz-white-bg-84.png"
            alt="Workflow"
          />
          <Spinner
            border="border-b-4"
            width="w-9"
            height="h-9"
            color="border-white"
          />
        </div>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn redirectUrl="/" />
      </SignedOut>
    </div>
  );
};

export default Index;
