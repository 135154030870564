export function getSessionCookie(): string | undefined {
  if (typeof document === 'undefined') {
    return undefined;
  }
  const session = document.cookie
    .split('; ')
    .find((row) => row.startsWith('__session='));
  return session ? session.split('=')[1] : '';
}

export function getSessionFromCookies(
  cookies: string | undefined
): string | undefined {
  const session = cookies
    ? cookies.split('; ').find((row) => row.startsWith('__session='))
    : undefined;
  return session ? session.split('=')[1] : '';
}
