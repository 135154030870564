import { classNames } from '@/utils/ui';

interface ISpinner {
  width?: string;
  height?: string;
  border?: string;
  color?: string;
}
const Spinner = ({ width, height, border, color }: ISpinner) => {
  return (
    <div className=" flex justify-center items-center pt-3">
      <div
        className={classNames(
          'animate-spin rounded-full',
          height || 'h-7',
          width || 'w-7',
          border || 'border-b-2',
          color || 'border-gray-900'
        )}
      ></div>
    </div>
  );
};

export { Spinner };
